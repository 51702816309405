import { Box, Column, Heading, Row, Txt } from '@stenajs-webui/core';
import { cssColor } from '@stenajs-webui/theme';
import { EnsButtonLink } from '../../../../common-ui/import-export/EnsButtonLink';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { ImportExportType } from '../../../../gql/graphql';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../../import-export/util/ImportExportSettingsGetters';
import { useUpdateFormStateMinimalBooking } from '../utils/minimalBooking';

interface OptionalEnsCardProps {
  booking: BasicBooking;
}

export function OptionalEnsCard({ booking }: OptionalEnsCardProps) {
  const minimalBooking = useUpdateFormStateMinimalBooking();

  const importSettings = getImportExportSettingsFromMinimalBooking(
    minimalBooking,
    getImportExportSettingsFromRoute(minimalBooking?.route, 'importReference'),
  );

  if (importSettings?.type !== ImportExportType.EnvelopeId || !importSettings.showEnsLink) {
    return null;
  }

  return (
    <Box
      borderRadius={16}
      background={cssColor('--lhds-color-ui-50')}
      gap={1}
      spacing={2}
      indent={3}
    >
      <Row gap={1} alignItems={'center'}>
        <img src={'/img/flags/flags-iso/flat/svg/gb.svg'} alt={''} width={24} height={18} />
        <Heading variant={'h5'}>Faster UK import?</Heading>
      </Row>
      <Column gap={1}>
        <Txt size={'small'}>
          Complete ENS at Stena Line and speed up your UK pre-lodgement movement.
        </Txt>
        <EnsButtonLink
          ensUrl={booking.ensUrl}
          confirmed={booking.importReference.ensConfirmed}
          trackerCategory={'Details'}
          optional
        />
      </Column>
    </Box>
  );
}
